import { IOrderClientData, OrderType } from "../../types";
import { jsonParseString } from "../../utils/string";
import { ISalesChannel } from "../ecommerceApi/SalesChannel/types";
import { salesChannel as globalSalesChannelId } from "../../config";

export const gtmHandleDataLayer = (
    orderClientData: Partial<IOrderClientData>,
    salesChannel?: ISalesChannel,
    gtm?: any
) => {
    const dataLayer = window?.["dataLayer"];

    let events: string[] = [];

    if (gtm && gtm?.config) {
        let property = (gtm?.config ?? []).find(el => el.property === "dlEvents");
        if (property) {
            events = jsonParseString(property?.value) ?? [];
        }
    }

    if (!dataLayer) return;

    const salesChannelId = salesChannel?.id || globalSalesChannelId;

    const data =
        orderClientData.parsed || (orderClientData.data ? JSON.parse(orderClientData.data) : {});

    const order: OrderType | undefined = data.order || undefined;

    switch (orderClientData.action) {
        case "identify":
            dataLayer.push({
                event: "identify",
                userData: {
                    $email: data?.email,
                    $first_name: data?.first_name,
                    $last_name: data?.last_name
                }
            });
            break;
        case "addToCart":
            if (order) {
                dataLayer.push({
                    event: "add_to_cart",
                    ecommerce: {
                        value: order.totalAmount,
                        currency: order.currencyCode,
                        items: order.orderItems
                            .filter(oi => !oi.parentId)
                            .map(item => {
                                const schema = JSON.parse(item?.schema ?? "{}");
                                let seoData: any = {};
                                if (Array.isArray(schema)) {
                                    seoData = schema.find(s => s.salesChannelId === salesChannelId);
                                } else {
                                    seoData = schema || {};
                                }
                                return {
                                    item_id: item.code || item.skuId,
                                    item_name: item.name,
                                    affiliation: salesChannel?.name,
                                    index: 0,
                                    price: item.unitAmount,
                                    quantity: item.quantity,
                                    item_brand: seoData?.brand
                                };
                            })
                    }
                });
            }
            break;
        case "beginCheckout":
            if (order) {
                dataLayer.push({
                    event: "begin_checkout",
                    ecommerce: {
                        value: order.totalAmount,
                        currency: order.currencyCode,
                        coupon: (order?.couponsUsed ?? [])?.map(el => el.couponCode).join(","),
                        items: order.orderItems
                            .filter(oi => !oi.parentId)
                            .map(item => {
                                const schema = JSON.parse(item?.schema ?? "{}");
                                let seoData: any = {};
                                if (Array.isArray(schema)) {
                                    seoData = schema.find(
                                        s => s.salesChannelId === salesChannel?.id
                                    );
                                } else {
                                    seoData = schema || {};
                                }

                                return {
                                    item_id: item.code || item.skuId,
                                    item_name: item.name,
                                    affiliation: salesChannel?.name,
                                    index: 0,
                                    price: item.unitAmount,
                                    quantity: item.quantity,
                                    item_brand: seoData?.brand
                                };
                            })
                    }
                });
            }
            break;
        case "purchase":
            if (order && events.includes("purchase")) {
                dataLayer.push({
                    event: "purchase",
                    ecommerce: {
                        transaction_id: order.number,
                        value: order.totalAmount,
                        tax: order.totalTax,
                        shipping: order.shippingAmount,
                        currency: order.currencyCode,
                        coupon: (order?.couponsUsed ?? [])?.map(el => el.couponCode).join(","),
                        items: order.orderItems
                            .filter(oi => !oi.parentId)
                            .map(item => {
                                const schema = JSON.parse(item?.schema ?? "{}");
                                let seoData: any = {};
                                if (Array.isArray(schema)) {
                                    seoData = schema.find(s => s.salesChannelId === salesChannelId);
                                } else {
                                    seoData = schema || {};
                                }
                                let itemDiscount = (
                                    order.discountAmount / order.itemsCount
                                ).toFixed(2);

                                return {
                                    item_id: item.code || item.skuId,
                                    item_name: item.name,
                                    affiliation: salesChannel?.name,
                                    index: 0,
                                    coupon: (order?.couponsUsed ?? [])
                                        ?.map(el => el.couponCode)
                                        .join(","),
                                    discount: parseFloat(itemDiscount),
                                    price: item.unitAmount,
                                    quantity: item.quantity,
                                    item_brand: seoData?.brand
                                };
                            })
                    }
                });
            }
            break;
        case "addToCartLink":
            const cartLinkId = data.cartLinkId;
            dataLayer.push({
                event: "addToCartLink",
                cartLinkId
            });
            break;
    }
};
